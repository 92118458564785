<!-- TransferStatsCard.vue -->
<template>
    <b-card
      title="Estatísticas de Transferência"
      header-bg-variant="dark"
      header-text-variant="white"
    >
      <b-card-text>
        <b-row>
          <b-col>Valor total transferido:</b-col>
          <b-col>{{ totalValue }}</b-col>
        </b-row>
        <b-row>
          <b-col>Contagem de transferências:</b-col>
          <b-col>{{ count }}</b-col>
        </b-row>
      </b-card-text>
    </b-card>
  </template>
  
  <script>
  export default {
    props: {
      totalValue: Number,
      count: Number,
    },
  };
  </script>
  